import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../lib/config";
import { useForm } from "react-hook-form";

const AdminSet = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user !== null) {
      const myuser = JSON.parse(user);
      if (myuser.name !== "admin") navigate("/");
    } else {
      navigate("/");
    }
  }, [navigate]);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(API_URL + "/set")
        .then((res) => {
          reset({
            adress: res.data?.adress,
            tel: res.data?.tel,
            mail: res.data?.mail,
            hakkimizda: res.data?.hakkimizda,
            BannerOneCheck: res.data?.banners?.bannerOne?.check,
            BannerTwoCheck: res.data?.banners?.bannerTwo?.check,
            BannerThreeCheck: res.data?.banners?.bannerThree?.check,
          });

          setImageOne(API_URL + res.data?.banners?.bannerOne?.url);
          setImageTwo(API_URL + res.data?.banners?.bannerTwo?.url);
          setImageThree(API_URL + res.data?.banners?.bannerThree?.url);
          setMobilOne(API_URL + res.data?.banners?.bannerOne?.mobilurl);
          setMobilTwo(API_URL + res.data?.banners?.bannerTwo?.mobilurl);
          setMobilThree(API_URL + res.data?.banners?.bannerThree?.mobilurl);
        })
        .catch((error) => {
          alert("Hata Oluştu");
        });
    };

    fetchData();
  }, [reset]);

  const onSubmit = async (data) => {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }
    // for (const pair of formData.entries()) {
    //   const fieldNames = pair[0];
    //   const imgdata = pair[1];
    //   console.log("Field: " + fieldNames + " Value :" + imgdata);
    // }
    try {
      const response = await axios.post(API_URL + "/upset", formData);
      if (response.data) {
        alert("Başarıyla Değiştirildi");
        window.location.reload();
      }
    } catch (error) {
      if (error.response && error.response.status === 500) alert("Hata Oluştu");
    }
  };
  const [imageOne, setImageOne] = useState();
  const [imageTwo, setImageTwo] = useState();
  const [imageThree, setImageThree] = useState();
  const [mobilOne, setMobilOne] = useState();
  const [mobilTwo, setMobilTwo] = useState();
  const [mobilThree, setMobilThree] = useState();
  const handleImageOne = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageOne(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleImageTwo = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageTwo(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleImageThree = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageThree(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleMobilOne = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setMobilOne(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleMobilTwo = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setMobilTwo(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };
  const handleMobilThree = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setMobilThree(e.target.result);
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <Layout>
      <form
        id="MyContactForm"
        name="KuryeForm"
        className="tw-border tw-border-white tw-p-3"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2">
              <div className="tw-flex tw-items-center tw-justify-center tw-gap-3 tw-border tw-border-white tw-p-3">
                <div className="tw-text-white tw-text-xs">
                  Banner Res-1: (1600x820)
                </div>
                <img
                  src={imageOne}
                  className="tw-w-[230px] tw-h-[120px] tw-object-contain"
                  alt="img1"
                />
                <div className="tw-flex tw-flex-col tw-justify-start">
                  <input
                    type="file"
                    accept="image/*"
                    className="tw-max-w-60"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const maxSize = 3 * 1024 * 1024;

                      if (file.size > maxSize) {
                        alert("Dosya boyutu 3MB'dan fazla olamaz.");
                        e.target.value = "";
                      } else {
                        handleImageOne(e);
                        register(`BannerImageOne`, {
                          shouldUnregister: true,
                          value: file,
                        });
                      }
                    }}
                  />
                  <div className="tw-flex tw-justify-start">
                    <input
                      type="checkbox"
                      className="tw-mb-0 tw-w-12 tw-min-h-4 !tw-px-2"
                      {...register("BannerOneCheck")}
                    />
                    <div className="tw-text-white">EKLE/KALDIR</div>
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-justify-center tw-gap-3 tw-border tw-border-white tw-p-3">
                <div className="tw-text-white tw-text-xs">
                  Banner Res-2: (1600x820)
                </div>
                <img
                  src={imageTwo}
                  className="tw-w-[230px] tw-h-[120px] tw-object-contain"
                  alt="img1"
                />
                <div className="tw-flex tw-flex-col tw-justify-start">
                  <input
                    type="file"
                    accept="image/*"
                    className="tw-max-w-60"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const maxSize = 3 * 1024 * 1024;

                      if (file.size > maxSize) {
                        alert("Dosya boyutu 3MB'dan fazla olamaz.");
                        e.target.value = "";
                      } else {
                        handleImageTwo(e);
                        register(`BannerImageTwo`, {
                          shouldUnregister: true,
                          value: file,
                        });
                      }
                    }}
                  />
                  <div className="tw-flex tw-justify-start">
                    <input
                      type="checkbox"
                      className="tw-mb-0 tw-w-12 tw-min-h-4 !tw-px-2"
                      {...register("BannerTwoCheck")}
                    />
                    <div className="tw-text-white">EKLE/KALDIR</div>
                  </div>
                </div>
              </div>
              <div className="tw-flex tw-items-center tw-justify-center tw-gap-3 tw-border tw-border-white tw-p-3">
                <div className="tw-text-white tw-text-xs">
                  Banner Res-3: (1600x820)
                </div>
                <img
                  src={imageThree}
                  className="tw-w-[230px] tw-h-[120px] tw-object-contain"
                  alt="img1"
                />
                <div className="tw-flex tw-flex-col tw-justify-start">
                  <input
                    type="file"
                    accept="image/*"
                    className="tw-max-w-60"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const maxSize = 3 * 1024 * 1024;

                      if (file.size > maxSize) {
                        alert("Dosya boyutu 3MB'dan fazla olamaz.");
                        e.target.value = "";
                      } else {
                        handleImageThree(e);
                        register(`BannerImageThree`, {
                          shouldUnregister: true,
                          value: file,
                        });
                      }
                    }}
                  />
                  <div className="tw-flex tw-justify-start">
                    <input
                      type="checkbox"
                      className="tw-mb-0 tw-w-12 tw-min-h-4 !tw-px-2"
                      {...register("BannerThreeCheck")}
                    />
                    <div className="tw-text-white">EKLE/KALDIR</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-gap-2">
              <div className="tw-flex tw-items-center tw-justify-center tw-gap-3 tw-border tw-border-white tw-p-3">
                <div className="tw-text-white tw-text-xs">
                  Banner Mobil Res-1: (1600x820)
                </div>
                <img
                  src={mobilOne}
                  className="tw-w-[150px] tw-h-[120px] tw-object-contain"
                  alt="img1"
                />

                <input
                  type="file"
                  accept="image/*"
                  className="tw-max-w-60"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const maxSize = 3 * 1024 * 1024;

                    if (file.size > maxSize) {
                      alert("Dosya boyutu 3MB'dan fazla olamaz.");
                      e.target.value = "";
                    } else {
                      handleMobilOne(e);
                      register(`BannerMobilOne`, {
                        shouldUnregister: true,
                        value: file,
                      });
                    }
                  }}
                />
              </div>
              <div className="tw-flex tw-items-center tw-justify-center tw-gap-3 tw-border tw-border-white tw-p-3">
                <div className="tw-text-white tw-text-xs">
                  Banner Mobil Res-2: (1600x820)
                </div>
                <img
                  src={mobilTwo}
                  className="tw-w-[150px] tw-h-[120px] tw-object-contain"
                  alt="img1"
                />

                <input
                  type="file"
                  accept="image/*"
                  className="tw-max-w-60"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const maxSize = 3 * 1024 * 1024;

                    if (file.size > maxSize) {
                      alert("Dosya boyutu 3MB'dan fazla olamaz.");
                      e.target.value = "";
                    } else {
                      handleMobilTwo(e);
                      register(`BannerMobilTwo`, {
                        shouldUnregister: true,
                        value: file,
                      });
                    }
                  }}
                />
              </div>
              <div className="tw-flex tw-items-center tw-justify-center tw-gap-3 tw-border tw-border-white tw-p-3">
                <div className="tw-text-white tw-text-xs">
                  Banner Mobil Res-3: (1600x820)
                </div>
                <img
                  src={mobilThree}
                  className="tw-w-[150px] tw-h-[120px] tw-object-contain"
                  alt="img1"
                />

                <input
                  type="file"
                  accept="image/*"
                  className="tw-max-w-60"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    const maxSize = 3 * 1024 * 1024;

                    if (file.size > maxSize) {
                      alert("Dosya boyutu 3MB'dan fazla olamaz.");
                      e.target.value = "";
                    } else {
                      handleMobilThree(e);
                      register(`BannerMobilThree`, {
                        shouldUnregister: true,
                        value: file,
                      });
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tw-mt-6">
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <input
                  type="text"
                  id="tel"
                  placeholder="Telefon"
                  {...register(`tel`)}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <input
                  type="text"
                  id="tel"
                  placeholder="Mail"
                  {...register(`mail`)}
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <textarea
                  id="adress"
                  placeholder="Adress Giriniz*"
                  rows="3"
                  {...register("adress")}
                ></textarea>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <textarea
                  id="hakkimizda"
                  placeholder="Hakkımızda*"
                  rows="3"
                  {...register("hakkimizda")}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div className="tw-w-full tw-grid tw-place-content-center">
              <a
                href={`${API_URL}/public/excel/delivery.xlsx`}
                className="contact-btn tw-my-4 tw-border-white  tw-text-white tw-px-6 tw-max-w-80"
              >
                Excel Dosyasını İndir
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
            <div className="tw-w-full tw-grid tw-place-content-center">
              <div className="tw-text-white">
                Not: Adı ve Uzantısı aynı ( delivery.xlsx ) olacak
              </div>
              <input
                type="file"
                className="contact-btn tw-my-4 tw-border-white  tw-text-white tw-px-6 tw-max-w-80"
                accept=".xlsx"
                onChange={(e) => {
                  const file = e.target.files[0];
                  register(`excelfile`, {
                    shouldUnregister: true,
                    value: file,
                  });
                }}
              />
            </div>
          </div>
          <div className="text-center ">
            <input
              type="submit"
              className="contact-btn tw-my-4 tw-border-white  tw-text-white"
              value="DEĞİŞTİR"
            />
          </div>
        </div>
      </form>
    </Layout>
  );
};

export default AdminSet;
