import Footer1 from "../components/Footer/Footer1";
import Header1 from "../components/Header/Header1";

import SimpleSlider1 from "../components/Sliders/Slick-slider1";

const OdemeHata = () => {
  return (
    <>
      <div id="home" className="full-screen-block">
        <Header1 />
        <SimpleSlider1 />
      </div>
      <div className="tw-w-full tw-h-full">
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-pb-12 tw-text-white tw-text-xl">
          <img
            src={"/img/errortick.png"}
            className="tw-w-[400px] tw-h-[400px] tw-object-contain"
            alt="error"
          />
          <div>Siparişinizde bir hata oluştu ve başarısız sonuçlanmıştır.</div>
          <div>Lütfen tekrar deneyiniz.</div>
          <div>Teşekkürler.</div>
        </div>
      </div>
      <Footer1 />
    </>
  );
};

export default OdemeHata;
